/**
 * Creates a keyboard navigation handler for dropdown/suggestion lists
 *
 * @param {Object} options - Configuration options
 * @param {Function} options.onEscape - Function to call when Escape key is pressed
 * @param {Function} options.onArrowDown - Function to call when Down Arrow key is pressed
 * @param {Function} options.onArrowUp - Function to call when Up Arrow key is pressed
 * @param {Function} options.onEnter - Function to call when Enter key is pressed
 * @param {Function} options.afterKeyHandler - Function to call after any key is handled
 * @returns {Function} Keyboard event handler function
 */
export const createKeyboardNavigationHandler = ({
  onEscape,
  onArrowDown,
  onArrowUp,
  onEnter,
  afterKeyHandler,
}) => {
  return (event) => {
    let handled = true;

    switch (event.keyCode) {
      case 27: // Escape key
        if (onEscape) onEscape(event);
        break;

      case 40: // Down arrow
        if (onArrowDown) onArrowDown(event);
        break;

      case 38: // Up arrow
        if (onArrowUp) onArrowUp(event);
        break;

      case 13: // Enter
        if (onEnter) {
          event.preventDefault();
          onEnter(event);
        }
        break;

      default:
        handled = false;
        break;
    }

    if (afterKeyHandler) {
      afterKeyHandler(event, handled);
    }
  };
};
