import * as React from 'react';

export const FasterSpeed = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="36"
      viewBox="0 0 45 36"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7102 23.6477C19.3413 23.6477 16.6077 26.3813 16.6077 29.7502C16.6077 33.1223 19.3413 35.8528 22.7102 35.8528C26.0823 35.8528 28.8128 33.1192 28.8128 29.7502C28.8128 26.3782 26.0792 23.6477 22.7102 23.6477Z"
        fill="#DE3700"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18788 13.4355C15.2068 4.19646 29.7952 4.19646 38.8111 13.4355C40.0384 14.6907 42.0499 14.7155 43.3082 13.4913C44.5634 12.2639 44.5882 10.2525 43.364 8.99417C31.8501 -2.80177 13.152 -2.80177 1.63811 8.99417C0.410786 10.2494 0.43558 12.2639 1.6939 13.4882C2.94911 14.7155 4.96366 14.6907 6.18788 13.4355ZM14.243 21.6858C18.8113 17.0028 26.1877 17.0028 30.756 21.6858C31.9834 22.941 33.9948 22.9658 35.2531 21.7416C36.5083 20.5143 36.5331 18.5028 35.3089 17.2445C28.2456 10.0045 16.7565 10.0045 9.69319 17.2445C8.46587 18.4997 8.49066 20.5143 9.74898 21.7416C11.0042 22.9689 13.0156 22.9441 14.243 21.6858Z"
        fill="#0096FA"
      />
    </svg>
  );
};
