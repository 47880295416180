import {
  getLegalInformationBySources,
  getLegalInformationByHandles,
} from '@red-digital/hughesnet-developer-kit';
const legalInfo = getLegalInformationBySources(['site']);
const mirPrice = getLegalInformationByHandles(['mir_price']);
export const PROMO_END_DATE = legalInfo?.endDate;
export const START_DATE = legalInfo?.startDate;
export const RECEIVE_BY_DATE = legalInfo?.receiveByDate;
export const LEGAL_DISCLOSURES = legalInfo?.legalStatements;
export const MIR_PRICE = mirPrice.legalStatements[0].statement;
