import * as React from 'react';

export const Number1 = (props) => {
  return (
    <svg
      width="15"
      height="33"
      viewBox="0 0 15 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2637 32.3177H8.08791V7.7683L0 10.6694V5.35072L13.9341 0.317749H14.2637V32.3177Z"
        fill="black"
      />
    </svg>
  );
};
