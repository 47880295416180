/**
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/add-page-action
 */
export const addPageAction = (eventName, attributes = { isBaseSite: true }) => {
  const { newrelic } = window;
  if (typeof newrelic === 'object') {
    newrelic.addPageAction(eventName, attributes);
  }
};

/**
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/set-custom-attribute
 */
export const setCustomAttribute = (attributeName, attributeValue) => {
  const { newrelic } = window;
  if (typeof newrelic === 'object') {
    newrelic.setCustomAttribute(attributeName, attributeValue);
  }
};

/**
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/
 */
export const noticeError = (error, customAttributes = {}) => {
  const { newrelic } = window;
  if (typeof newrelic === 'object') {
    newrelic.noticeError(error, customAttributes);
  }
};
