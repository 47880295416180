import React from 'react';

export const Support = (props) => {
  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.5303 4.1394C11.6653 4.83283 10.0745 6.04972 8.91423 7.67225C7.70414 9.36503 7.06283 11.3615 7.06057 13.4417C7.06057 13.9493 6.95633 14.4434 6.74785 14.9079L5.24769 18.2799C5.06414 18.6923 5.06187 19.1659 5.24542 19.5783C5.42671 19.9908 5.77796 20.3103 6.20625 20.4508L7.06057 20.7341V23.6007C7.06057 24.9558 8.16189 26.0571 9.51702 26.0571H12.4267L12.9751 28.0241C13.0974 28.4637 13.3966 28.8422 13.7954 29.0642C14.1942 29.2863 14.6724 29.3407 15.112 29.2115H15.1143L24.5344 26.5353C24.9741 26.4129 25.3525 26.1115 25.5746 25.7149C25.7989 25.3116 25.851 24.8425 25.7241 24.3983L24.4551 19.9477L24.5299 19.8593C26.5445 17.4958 27.3353 14.2757 26.6487 11.2459C25.9893 8.34528 24.0268 5.86844 21.3664 4.5541C21.4389 5.20673 21.5024 5.8707 21.5545 6.53014C23.6008 7.8762 24.9355 10.0381 25.2233 12.4741C25.5111 14.9192 24.7089 17.3372 23.0229 19.1229L22.64 19.5217L24.1877 24.9535L14.5772 27.6978L13.6526 24.4414H9.51702C9.0638 24.4414 8.69442 24.072 8.69442 23.6165V20.4735C8.69442 19.9296 8.34771 19.4492 7.83104 19.2792L6.74785 18.9144L8.25027 15.5469C8.54713 14.883 8.70349 14.1465 8.70122 13.4213C8.70575 11.7626 9.1975 10.1627 10.1266 8.78944C11.024 7.4615 12.2703 6.43269 13.741 5.80272C13.6753 5.23846 13.6051 4.67873 13.5303 4.1394Z"
        fill="#0096FA"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.8713 3.69752C19.1775 5.67792 19.3137 8.22782 19.3462 10.3725C20.7322 11.0546 21.6881 12.4818 21.6881 14.1284C21.6881 16.4353 19.8118 18.3116 17.5049 18.3116C17.2052 18.3116 16.9128 18.2799 16.6309 18.2198C15.9481 19.0492 14.9516 20.1 14.1752 20.8604C14.0757 21.234 13.8199 21.6054 13.4367 21.8782C12.7026 22.4007 11.7792 22.3631 11.3742 21.7941C10.9692 21.2252 11.236 20.3404 11.9702 19.8178C12.4294 19.4909 12.9627 19.3832 13.3968 19.4877C14.0165 18.8855 14.6052 18.2525 15.1605 17.5915C14.0515 16.8384 13.3217 15.5671 13.3217 14.1284C13.3217 12.1114 14.756 10.4236 16.6583 10.0312C16.6288 7.87247 16.4307 5.7149 16.0659 3.57742C16.5463 3.52076 17.029 3.49131 17.5139 3.49131C17.7541 3.49131 17.9944 3.4981 18.2323 3.51397C18.707 3.54046 18.773 3.60352 18.8641 3.69057L18.8713 3.69752ZM17.5049 11.0148C15.7872 11.0148 14.3913 12.4107 14.3913 14.1284C14.3913 15.8461 15.7872 17.242 17.5049 17.242C19.2226 17.242 20.6185 15.8461 20.6185 14.1284C20.6185 12.4107 19.2226 11.0148 17.5049 11.0148Z"
        fill="#DE3700"
      />
    </svg>
  );
};
