import * as React from 'react';

export const Number2 = (props) => {
  return (
    <svg
      width="23"
      height="33"
      viewBox="0 0 23 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.1789 32.3177H0.650406V28.1985L10.8618 17.2283C11.6567 16.3611 12.3288 15.5734 12.878 14.8652C13.4273 14.157 13.8753 13.5065 14.2222 12.914C14.5547 12.3358 14.7931 11.7938 14.9377 11.2879C15.0967 10.7821 15.1762 10.2906 15.1762 9.81368C15.1762 9.10546 15.075 8.47674 14.8726 7.92751C14.6703 7.37827 14.3884 6.90854 14.0271 6.51829C13.6513 6.1425 13.2033 5.85343 12.6829 5.65108C12.1771 5.44873 11.6134 5.34756 10.9919 5.34756C10.1825 5.34756 9.46703 5.46319 8.84553 5.69444C8.23848 5.91124 7.73261 6.23645 7.32791 6.67005C6.90876 7.11811 6.59079 7.67457 6.37398 8.33943C6.15718 9.00429 6.04878 9.7631 6.04878 10.6159H0C0 9.21386 0.267389 7.89137 0.802168 6.64837C1.33695 5.39092 2.09575 4.29246 3.07859 3.35298C4.04697 2.4135 5.21771 1.67638 6.59079 1.1416C7.97832 0.592365 9.51039 0.317749 11.187 0.317749C12.7913 0.317749 14.2078 0.541778 15.4363 0.989836C16.6793 1.43789 17.72 2.06662 18.5583 2.87601C19.3821 3.68541 20.0108 4.66102 20.4444 5.80284C20.878 6.93021 21.0949 8.18044 21.0949 9.55352C21.0949 10.5942 20.9286 11.5915 20.5962 12.5454C20.2782 13.4849 19.8229 14.4171 19.2304 15.3421C18.6233 16.2672 17.9006 17.2139 17.0623 18.1822C16.224 19.1362 15.2918 20.1407 14.2656 21.1958L8.3252 27.4831H22.1789V32.3177Z"
        fill="black"
      />
    </svg>
  );
};
