export const ERRORS = {
  SMARTY_STREETS: {
    type: 'SmartyStreetsError',
    message: 'Error fetching suggestions from Smarty Streets',
  },
  NEW_RELIC: {
    type: 'NewRelicError',
    message: 'Error submitting address form',
  },
  ADDRESS_FORM_SUBMISSION: {
    type: 'AddressFormSubmissionError',
    message: 'Error submitting address form',
  },
  LOGROCKET: {
    type: 'LogRocketError',
    message: 'Error with LogRocket tracking',
  },
  FUSE: {
    type: 'FuseError',
    message: 'Fuse failed to load',
  },
  COHESION: {
    type: 'CohesionError',
    message: 'Cohesion error',
  },
};
