import { noticeError, addPageAction } from '@helpers/utils/newRelic';
import { suggestionClicked } from '@constants/newRelicActions';
import { ERRORS } from '@constants/errors';
import { SMARTY_STREETS } from '@constants/smartyStreets';

const { AUTOCOMPLETE_URL } = SMARTY_STREETS;

// https://www.smarty.com/docs/cloud/us-autocomplete-pro-api#pro-secondary-expansion
export const formatForAutocomplete = (address) => {
  return address.replace('#', '');
};

/**
 * Formats the address string based on the suggestion item
 * @param {Object} item - The address suggestion item
 * @returns {string} - Formatted address string
 */
export const formatAddressString = (item) => {
  if (item.secondary) {
    return `${item.street_line}, ${item.secondary} - ${item.city}, ${item.state} ${item.zipcode}`;
  }
  return `${item.street_line} - ${item.city}, ${item.state} ${item.zipcode}`;
};

/**
 * Tracks the suggestion click event
 * @param {string} location - The component location
 */
const trackSuggestionClick = (location, tagularObj = {}) => {
  const { position, htmlId, elementType, text, actionOutcome } = tagularObj;

  window.tagular('beam', 'ElementClicked', {
    '@type': 'redventures.usertracking.v3.ElementClicked',
    webElement: {
      location: location,
      position: position,
      htmlId: htmlId,
      elementType: elementType,
      text: text,
    },
    actionOutcome: actionOutcome,
  });
};

// Generic function to fetch address suggestions from SmartyStreets API
export const fetchAddressSuggestions = async (query) => {
  try {
    const response = await fetch(`${AUTOCOMPLETE_URL}${query}`);
    const body = await response.json();

    // Filter out Puerto Rico addresses if they exist
    const suggestions = body?.suggestions?.filter(
      (suggestion) => suggestion.state !== 'PR'
    );
    return suggestions;
  } catch (error) {
    noticeError(error, {
      type: ERRORS.SMARTY_STREETS.type,
      message: ERRORS.SMARTY_STREETS.message,
    });
    return null;
  }
};

/**
 * Handles address suggestion click
 * @param {Object} item - The suggestion item that was clicked
 * @param {string} apiKey - The Smarty Streets API key
 * @param {string} location - The component location for tracking
 * @param {Function} setAddress - Function to update address state
 * @param {Function} setFinalAddress - Function to update final address state
 * @param {Function} setZipCode - Function to update zip code state
 * @param {Function} setSuggestions - Function to update suggestions state
 * @param {Function} setShowSuggestions - Function to toggle suggestions visibility
 * @param {Function} setShowNoApartmentSuggestions - Function to toggle no apartment suggestions message
 * @param {Function} checkFormButton - Function to check form button state
 * @returns {Promise<void>}
 */
export const handleSuggestionClick = async (
  item,
  apiKey,
  location,
  fetchSuggestions,
  setAddress,
  setFinalAddress,
  setZipCode,
  setSuggestions,
  setShowSuggestions,
  setShowNoApartmentSuggestions,
  checkFormButton,
  tagularObj
) => {
  const selected = `${item.street_line} ${formatForAutocomplete(
    item.secondary
  )} (${item.entries}) ${item.city} ${item.state} ${item.zipcode}`;

  addPageAction(suggestionClicked);

  if (item.secondary && item.entries > 1) {
    const searchQuery = `?auth-id=${apiKey}&search=${
      item.street_line
    }&selected=${formatForAutocomplete(selected)}`;

    const suggestions = await fetchSuggestions(searchQuery);

    if (suggestions && suggestions.length > 0) {
      setSuggestions(suggestions);
    } else {
      setShowNoApartmentSuggestions(true);
    }
  } else {
    setAddress(formatAddressString(item));
    setFinalAddress(item);
    setZipCode(item.zipcode);
    setShowSuggestions(false);
    trackSuggestionClick(location, tagularObj);
    checkFormButton();
  }
};
