export const setCookie = (sName, sValue, options) => {
  const cookieName = encodeURIComponent(sName);
  const cookieValue = encodeURIComponent(sValue);
  let sCookie = `${cookieName}=${cookieValue}`;

  if (options && typeof options === 'object') {
    if (options.expires) {
      sCookie += `; expires=${options.expires.toGMTString()}`;
    }
    if (options.domain) {
      sCookie += `; domain=${options.domain.toString()}`;
    }
    if (options.secure) {
      sCookie += '; secure';
    }
  }
  document.cookie = sCookie;
};

export const setTimedCookie = (name, value, exmins) => {
  const d = new Date();
  d.setTime(d.getTime() + exmins * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};
