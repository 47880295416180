import { createContext, useState, useEffect } from 'react';
import { logError } from '@red-digital/bricks';
import { noticeError } from '@helpers/utils/newRelic';
import { ERRORS } from '@constants/errors';

export const FuseContext = createContext();

export function FuseProvider({ children }) {
  const [fuseNumber, setFuseNumber] = useState('');
  const defaultFuseNumber = '8882551881';

  const fuseLeaseRecieved = (setFuseNumber) => {
    return (leases) => {
      setFuseNumber(leases?.[0]?.dnis);
      return leases?.[0]?.dnis;
    };
  };

  const fuseError = (setFuseNumber, defaultFuseNumber) => {
    return () => {
      setFuseNumber(defaultFuseNumber);

      const error = new Error(ERRORS.FUSE.message);
      logError(error);
      noticeError(error, { type: ERRORS.FUSE.type });
      return true;
    };
  };

  useEffect(() => {
    try {
      window?.cohesion('fuse:leasesReceived', fuseLeaseRecieved(setFuseNumber));

      window?.cohesion(
        'fuse:error',
        fuseError(setFuseNumber, defaultFuseNumber)
      );
    } catch (error) {
      logError(`cohesion:catch: ${error}`);
      noticeError(error, {
        type: ERRORS.COHESION.type,
        message: ERRORS.COHESION.message,
      });
    }
  }, []);

  return (
    <FuseContext.Provider value={{ fuseNumber, defaultFuseNumber }}>
      {children}
    </FuseContext.Provider>
  );
}
