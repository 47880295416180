import React from 'react';

export const FiveStarsReliability = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="120"
      height="25"
      viewBox="0 0 120 25"
      fill="none"
      {...props}
    >
      <path
        d="M12 2.31775L12 9.81775L12 12.3177V15.2344V17.3177V18.3486L5.81966 22.3177L7.5 14.9109L2 9.95707L9.21885 9.34856L12 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M12 2.31775L12 9.81775L12 12.3177V15.2344V17.3177V18.3486L18.1803 22.3177L16.5 14.9109L22 9.95707L14.7812 9.34856L12 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M36 2.31775L36 9.81775L36 12.3177V15.2344V17.3177V18.3486L29.8197 22.3177L31.5 14.9109L26 9.95707L33.2188 9.34856L36 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M36 2.31775L36 9.81775L36 12.3177V15.2344V17.3177V18.3486L42.1803 22.3177L40.5 14.9109L46 9.95707L38.7812 9.34856L36 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M60 2.31775L60 9.81775L60 12.3177V15.2344V17.3177V18.3486L53.8197 22.3177L55.5 14.9109L50 9.95707L57.2188 9.34856L60 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M60 2.31775L60 9.81775L60 12.3177V15.2344V17.3177V18.3486L66.1803 22.3177L64.5 14.9109L70 9.95707L62.7812 9.34856L60 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M84 2.31775L84 9.81775L84 12.3177V15.2344V17.3177V18.3486L77.8197 22.3177L79.5 14.9109L74 9.95707L81.2188 9.34856L84 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M84 2.31775L84 9.81775L84 12.3177V15.2344V17.3177V18.3486L90.1803 22.3177L88.5 14.9109L94 9.95707L86.7812 9.34856L84 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M108 2.31775L108 9.81775L108 12.3177V15.2344V17.3177V18.3486L101.82 22.3177L103.5 14.9109L98 9.95707L105.219 9.34856L108 2.31775Z"
        fill="#0096FA"
      />
      <path
        d="M108 2.31775L108 9.81775L108 12.3177V15.2344V17.3177V18.3486L114.18 22.3177L112.5 14.9109L118 9.95707L110.781 9.34856L108 2.31775Z"
        fill="#0096FA"
      />
    </svg>
  );
};
