import { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { LEGAL_DISCLOSURES } from '@constants/legalInfo';

export const LegalContext = createContext();

export function LegalProvider({ children }) {
  const router = useRouter();
  const [disclosures, setDisclosures] = useState([]);
  const [activeLegal, setActiveLegal] = useState([]);

  useEffect(() => {
    setDisclosures(LEGAL_DISCLOSURES);
  }, []);
  // Reset active legal when the route changes
  useEffect(() => {
    setActiveLegal([]);
  }, [router.pathname]);

  // Context values passed to consumer
  const state = {
    disclosures,
    activeLegal,
    setActiveLegal,
  };

  return (
    <LegalContext.Provider value={state}>{children}</LegalContext.Provider>
  );
}
