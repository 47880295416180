export { addPageAction, setCustomAttribute } from './newRelic';
export { setCookie } from './setCookie';
export { setMCID } from './setMCID';
export { setCampaignId } from './setCampaignId';
export { getUrlParameter } from './getUrlParameter';
export { sanitizeNetworkData } from './sanitizeNetworkData';
export { getOfferEndDiff } from './getOfferEndDiff';
export { getCookie } from './getCookie';
export { setExistingCustomer } from './setExistingCustomer';
export {
  formatForAutocomplete,
  fetchAddressSuggestions,
  handleSuggestionClick,
} from './smartyStreets';
