import React, { useContext, useRef } from 'react';
import Link from 'next/link';
import styles from './Footer.module.scss';
import { v4 as uuid } from 'uuid';
import Typography from '~/components/shared/Typography';
import { LegalContext } from '~/providers/LegalProvider';
import { HughesnetLogoBrand } from '~/components/icons';
import { useMedia } from '~/helpers/hooks';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import { PrivacyCheckbox } from '~/components/icons';

export default function Footer() {
  const isMedia = useMedia();
  const { activeLegal } = useContext(LegalContext);
  const footer = useRef();

  const handleTracking = (tracking) => {
    tagular('beam', 'ElementClicked', {
      '@type': 'redventures.usertracking.v3.ElementClicked',
      webElement: {
        location: tracking.location || '',
        position: tracking.position || '',
        elementType: tracking.elementType || '',
        text: tracking.text || '',
        htmlId: tracking.htmlId || '',
      },
      actionOutcome: tracking.actionOutcome || '',
    });
  };

  useIntersectionObserver(
    [footer],
    () =>
      fireElementViewedEvent('Footer', 'Footer section', 'Footer', 'Footer'),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: isMedia.desktop ? 1.0 : isMedia.tablet ? 0.8 : 0.65 }
  );

  return (
    <footer id="finalEpq" className={styles.footer} ref={footer}>
      <div className="gridContainer">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <div className={styles.logoNav}>
              <Link
                prefetch={false}
                href="/"
                passHref
                aria-label="Hughesnet Logo"
              >
                <HughesnetLogoBrand
                  id="2"
                  className={styles.logo}
                  width="150"
                />
              </Link>
              <nav className={styles.upperNav}>
                {isMedia.mobile && (
                  <li className={styles.navItem}>
                    <a
                      href="https://my.hughesnet.com/myaccount"
                      target="_blank"
                      rel="noreferrer"
                      onClick={() =>
                        handleTracking({
                          location: 'FOOTER',
                          elementType: 'LINK',
                          text: 'My Account',
                          htmlId: 'FOOTER_MY_ACCOUNT',
                          actionOutcome: 'external link',
                        })
                      }
                    >
                      My Account
                    </a>
                  </li>
                )}
              </nav>
            </div>
            <div className={styles.legalAnno}>
              {activeLegal.map((text, index) => (
                <Typography variant="legal" key={uuid()}>
                  {`[${index + 1}]`} {text}
                </Typography>
              ))}
            </div>
            <div className={styles.legal}>
              <Typography variant="legal">
                24-month commitment and early service termination fees apply.
                Visit legal.hughesnet.com for details. Hughesnet and Hughesnet
                Fusion are registered trademarks of Hughes Network Systems, LLC,
                an EchoStar company. JUPITER is a trademark of Hughes Network
                Systems, LLC, an EchoStar company.
              </Typography>
            </div>
            <ul className={styles.lowerNav}>
              <li
                className={styles.navItem}
                onClick={() =>
                  handleTracking({
                    location: 'FOOTER',
                    elementType: 'LINK',
                    text: 'Legal',
                    htmlId: 'FOOTER_NAV_LEGAL',
                    actionOutcome: 'internal link',
                  })
                }
              >
                <Link
                  prefetch={false}
                  href="https://legal.hughesnet.com/TermsOfUse.cfm"
                >
                  Legal
                </Link>
              </li>
              <li className={styles.navItem}>
                <Link
                  href="/your-privacy-choices/"
                  rel="noreferrer"
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() =>
                    handleTracking({
                      location: 'FOOTER',
                      elementType: 'LINK',
                      text: 'Your Privacy Choices',
                      htmlId: 'FOOTER_NAV_PP',
                      actionOutcome: 'external link',
                    })
                  }
                >
                  <span>
                    Your Privacy Choices&nbsp;
                    <PrivacyCheckbox />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
